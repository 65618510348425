'use client'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { HiOutlineXMark } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/PanierContext';
import { useDisclosure } from '../contexts/DisclosureContext';


export default function Cart() {
  // Get the cart items from Redux store
  const { open, setOpen } = useDisclosure();
  const { Cart, totalPrice,removeCart } = useCart();
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-30">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full responsive pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-lg font-medium text-gray-900">Panier</DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Close panel</span>
                        <HiOutlineXMark aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flow-root">
                      {Cart.length === 0 ? (
                        <p className="text-center text-gray-500">Votre Panier est vide</p>
                      ) : (
                        <ul className="-my-6 divide-y divide-gray-200">
                          {Cart.map((product) => (
                            <li key={product.id} className="flex py-6">
                              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                  alt={product.titre}
                                  src={product.image}
                                  className="h-full w-full object-cover object-center"
                                />
                              </div>

                              <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                  <div className="flex justify-between items-start text-base font-medium text-gray-900">
                                    <h3>
                                      <a className="truncate-multiline uppercase" href={product.titre}>{product.titre}</a>
                                    </h3>
                                    <button onClick={() => removeCart(product.id)} className="ml-4"><HiOutlineXMark aria-hidden="true" className="h-5 w-5" /></button>
                                  </div>
                                  <p className="text-sm text-gray-500 uppercase">{product.categorie}</p>
                                </div>
                                <div className="flex flex-1 items-end justify-between text-sm">
                                  <p className="text-gray-500">{product.quantity}×{product.prix.toFixed(3)}TND</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                {Cart.length > 0 && (
                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Sous-total :</p>
                      <p>{totalPrice} TND</p>
                    </div>
                    <div className="mt-6">
                      <Link
                        to="/Validation"
                        onClick={setOpen}
                        className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                      >
                        COMMANDER
                      </Link>
                    </div>
                    <div className="mt-4 flex justify-center text-center text-sm text-gray-500">
                      <Link
                        to="/Panier"
                        onClick={() => setOpen(false)}
                        className="font-medium text-blue-500 border border-blue-500 hover:border-blue-700 hover:text-blue-400 w-full rounded-md px-6 py-3"
                      >
                        VOIR LE PANIER
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
