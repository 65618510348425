import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaPen } from "react-icons/fa6";
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { toast } from 'sonner';

function ModifyProduct({Produits}) {
  const [sureModal, setsureModal] = useState(false);
  const [product, setProduct] = useState({
    brand: '',
    categorie: '',
    description: '',
    ensolde: false,
    image:null,
    instock: true,
    prix: 0,
    solde: { oldprix: 0 },
    titre: '',
  });
  const [setLoading, setsetLoading] = useState(false);
  const setModal = (product) => {
     setProduct(product)
     setsureModal(true);
  }
  const handleChange = (e) => {
    const { name, value} = e.target;

    if (name === 'ensolde' || name === 'instock') {
        setProduct({ ...product, [name]: value === 'true' });
    } else if (name === 'prix') {
        setProduct({ ...product, prix: parseFloat(value) || null });
    } else {
        setProduct({ ...product, [name]: value });
    }
  };
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  const handleSet = async (e) => {
    e.preventDefault();
    setsetLoading(true);

    try {
      // Prepare the product data including the (possibly new) image URL
      const productData = product

      // Send the data using Axios to modify the product
      const response = await axios.put(`/.netlify/functions/modifyProduct`, productData);

      if (response.data.Status === "Success") {
        setsetLoading(false);
        setsureModal(false);
        toast.success('Product modified successfully');
      } 
    } catch (error) {
      console.error('Error during product modification:', error);

      toast.error('Error during product modification');
    }
  };
  return (
    <section className="flex flex-grow">
       <div className="w-full flex flex-col p-4">
          {sureModal && (
             <div className="absolute top-0 z-30 left-0 w-screen h-full bg-white p-2">
                 <div className="relative flex gap-4 items-center justify-center w-full h-full flex-col">
                   <div onClick={() => !setLoading && setsureModal(false)} className="cursor-pointer absolute top-0 right-0 p-2 text-[30px] hover:bg-gray-200 rounded-full">
                        <IoClose />
                   </div>
                   <form onSubmit={handleSet} className="max-w-lg mx-auto min-w-[250px] xs:min-w-[350px] p-6 bg-white mt-[50px] rounded-md">
                        <div className="mb-4">
                           <label className="block text-gray-700 font-bold mb-2">Brand:</label>
                           <select
                            name="brand"
                             value={product.brand}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                           >
                             <option value="" disabled>Select a brand</option>
                            <option value="Ultimate Nutrition">Ultimate Nutrition</option>
                             <option value="Optimum Nutrition">Optimum Nutrition</option>
                             <option value="Muscle Tech">Muscle Tech</option>
                            <option value="Nutrex">Nutrex</option>
                            <option value="Nutrend">Nutrend</option>
                            <option value="Biotech USA">Biotech USA</option>
                            <option value="Applied Nutrition">Applied Nutrition</option>
                             <option value="Olimp">Olimp</option>
                             <option value="Animal">Animal</option>
                             <option value="Impact Nutrition">Impact Nutrition</option>
                             <option value="Great Sport Nutrition">Great Sport Nutrition</option>
                             <option value="Hx Nutrition">Hx Nutrition</option>
                             <option value="Real Pharm">Real Pharm</option>
                           </select>
                        </div>
                        <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Category:</label>
                <select
             name="categorie"
              value={product.categorie}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="" disabled>Select a category</option>
              <option value="Mass Gainer">Mass Gainer</option>
              <option value="Whey Gainer">Whey Gainer</option>
              <option value="Acides Aminés">Acides Aminés</option>
              <option value="Preworkout">Preworkout</option>
              <option value="Créatine">Créatine</option>
              <option value="Multi Vitamine">Multi Vitamine</option>
              <option value="Brulures de graisse">Brulures de graisse</option>
              <option value="Accessoires">Accessoires</option>
              <option value="Snack">Snack</option>
                </select>
                        </div>
                        <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Description:</label>
               <textarea
                 name="description"
                 value={product.description}
                 onChange={handleChange}
                 className="w-full px-3 py-2 resize-none border border-gray-300 rounded-md"
               />
                        </div>
                        <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">In Stock:</label>
               <select
                 name="instock"
                 value={product.instock ? 'true' : 'false'}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               >
                 <option value="true">true</option>
                 <option value="false">false</option>
               </select>
                        </div>
                        <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Prix:</label>
               <input
                 type="number"
                 name="prix"
                 value={product.prix}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
                        </div>
                        <div className="mb-4">
               <label className="block text-gray-700 font-bold mb-2">Titre:</label>
               <input
                 type="text"
                 name="titre"
                 value={product.titre}
                 onChange={handleChange}
                 className="w-full px-3 py-2 border border-gray-300 rounded-md"
               />
                        </div>
                        {setLoading ? (
                          <button className="w-full mt-4 items-center justify-center flex text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>
                        ) : (
                          <button type="submit" className="w-full mt-4 text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200">Modifier</button>
                        )}
                    </form>
                 </div>
             </div>
          )}
          <div className="w-full flex items-center px-4 justify-start">
              <h1 className="Linky md:text-[50px] text-[25px]">Modifier Un Produit</h1>
          </div>
          <div className="w-full px-[40px] pt-[40px] h-[700px] overflow-auto scrollbar-custom">
          <div className="flex w-full flex-col">
                   {Produits.length !== 0 ? (
                        <div className="lg:px-0 md:px-20 sm:px-5 xmd:px-2 xxmd:px-20 px-4 gap-4 grid  lg:grid-cols-3 xmd:grid-cols-2 grid-cols-1">
                         {Produits.map((produit,index) => (
                          <div key={index} className="h-[400px] group relative overflow-y-hidden bg-gray-200 rounded-xl shadow group cursor-pointer">
                                <div onClick={() => setModal(produit)} className="text-white z-20 absolute group-hover:flex hidden items-center justify-center top-0 left-0 w-full h-full bg-black bg-opacity-20">
                                    <FaPen />
                                </div>
                                {produit.ensolde && (<div className="absolute top-3 left-3 bg-red-500 diagonal-rectangle rounded-sm text-[12px] text-white p-1">{calcPour(produit.solde.oldprix,produit.prix)}% OFF</div>)}
                                <Link to={`/produit/${produit.titre}`} className="flex flex-col">
                                   <div className="flex w-full h-[250px]">
                                     <img src={produit.image} alt="l'image de produit" className="rounded-t-xl w-full h-full"/>
                                   </div>
                                   <div className="flex flex-col w-full p-4 gap-[5px]">
                                         <h1 className="truncate-multiline hover:text-red-600 cursor-pointer uppercase">{produit.titre}</h1>
                                         <p className="hover:text-red-600 underline cursor-pointer text-[14px] text-gray-400 uppercase">{produit.categorie}</p>
                                         <div className="flex gap-2 items-center">
                                            {produit.ensolde && (
                                               <p className="line-through text-gray-400 text-[14px]">{produit.solde.oldprix.toFixed(3)}TND</p>
                                            )}
                                            <p className="text-red-500 text-[17px]">{produit.prix.toFixed(3)}TND</p>
                                         </div>
                                   </div>
                                </Link>
                           </div>      
                          ))} 
                        </div>
                    ) : (
                        <div className="text-[30px] text-center">No Produits</div>
                    )}  
               </div>
           </div>
       </div>
    </section>
  )
}

export default ModifyProduct