import axios  from 'axios';
import React,{useState} from 'react'
import { FaRegTrashAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { toast } from 'sonner';
import { FaPlus } from "react-icons/fa6";

function Soldes({Produits}) {
  const [sureModal, setsureModal] = useState(false);
  const [setLoading, setsetLoading] = useState(false);
  const [editdiscount, seteditdiscount] = useState(false);
  const [adddiscount, setadddiscount] = useState(false);
  const [productInfo, setproductInfo] = useState({
    titre:"",
    ensolde:false,
    prix:0,
    solde:{oldprix:0}
  });

  
  const setEditDiscountTrue = (produit) => {
    setproductInfo({
      titre:produit.titre,
      ensolde:produit.ensolde,
      prix:produit.prix,
      solde:{oldprix:produit.solde.oldprix}
    })
    seteditdiscount(true)
  }
  const setAddDiscountTrue = () => {
    setproductInfo({
      titre:"",
      ensolde:true,
      prix:0,
      solde:{oldprix:0}
    })
    setadddiscount(true)
  }
  const setModal = (produit) => {
    setsureModal(true)
    setproductInfo({
      titre:produit.titre,
      ensolde:produit.ensolde,
      prix:produit.prix,
      solde:{oldprix:produit.solde.oldprix}
    })
  }
  const calcPour = (oldPrice, newPrice) => {
    const discount = oldPrice - newPrice;
    const discountPercentage = (discount / oldPrice) * 100;
  
    return Math.round(discountPercentage); // Returns the discount percentage rounded to two decimal places
  }
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    const numericValue = name === "old prix" || name === "prix" ? parseFloat(value) : value;
  
    if (name === "old prix") {
      setproductInfo((prevInfo) => ({
        ...prevInfo,
        solde: { oldprix: numericValue }
      }));
    } else {
      setproductInfo((prevInfo) => ({
        ...prevInfo,
        [name]: numericValue
      }));
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = name === "old prix" || name === "prix" ? parseFloat(value) : value;
  
    if (name === "old prix") {
      setproductInfo((prevInfo) => ({
        ...prevInfo,
        solde: { oldprix: numericValue }
      }));
    } else {
      setproductInfo((prevInfo) => ({
        ...prevInfo,
        [name]: numericValue
      }));
    }
  };
  const handleDeleteDiscount = async () => {
    const newProductInfo = {
      ...productInfo,
      ensolde: false,
      solde:0,
      prix:productInfo.solde.oldprix
    }
    setproductInfo({
      ...productInfo,
      ensolde: false,
      solde:{oldprix:0},
      prix:productInfo.solde.oldprix
    })
    setsetLoading(true);
     try {
        const response = await axios.put(`/.netlify/functions/updateDiscount`, newProductInfo);
        if (response.data.Status === "Success") {
          setsetLoading(false);
          setsureModal(false)
          toast.success('Solde est supprimé');
        }
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors de la suppression du solde");
      }
  }
  const handleAddDiscount = async (e) => {
    e.preventDefault()
    setsetLoading(true);
    const updatedProductInfo = {
      ...productInfo,
      solde: productInfo.solde.oldprix, // Assuming oldprix is the intended value
    };
     try {
        const response = await axios.put(`/.netlify/functions/updateDiscount`, updatedProductInfo);
        if (response.data.Status === "Success") {
          setsetLoading(false);
          setadddiscount(false)
          toast.success('Solde est crée');
        }
      } catch (err) {
        console.error(err);
        setsetLoading(false);
        toast.error("Erreur lors de la suppression du solde");
      }
  }
  const handleEditDiscount = async (e) => {
    e.preventDefault()
    setsetLoading(true);
    const updatedProductInfo = {
      ...productInfo,
      solde: productInfo.solde.oldprix, // Assuming oldprix is the intended value
    };
    console.log(updatedProductInfo)
     try {
        const response = await axios.put(`/.netlify/functions/updateDiscount`, updatedProductInfo);
        if (response.data.Status === "Success") {
          setsetLoading(false);
          seteditdiscount(false)
          toast.success('Solde est modifié');
        }
      } catch (err) {
        console.error(err);
        setsetLoading(false);
        toast.error("Erreur lors de la suppression du solde");
      }
  }
  return (
    <section className="flex lg:flex-grow w-screen xmd:w-auto">
        <div className="w-full flex flex-col p-2 xmd:p-4">
           {editdiscount && (
             <div className="absolute top-0 z-30 left-0 w-screen h-full bg-white p-2">
                 <div className="relative flex gap-4 items-center justify-center w-full h-full flex-col">
                   <div onClick={() => !setLoading && seteditdiscount(false)} className="cursor-pointer absolute top-0 right-0 p-2 text-[30px] hover:bg-gray-200 rounded-full">
                        <IoClose />
                   </div>
                   <form onSubmit={handleEditDiscount} className="max-w-lg mx-auto min-w-[250px] xs:min-w-[350px] p-6 bg-white mt-[50px] rounded-md">
                        <div className="mb-4">
                           <label className="block text-gray-700 font-bold mb-2">Titre:</label>
                           <select
                            name="titre"
                            value={productInfo.titre}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                           >
                             <option value="" disabled>Choisir Le Titre</option>
                             {Produits.map((produit, index) => {
                                if (produit.titre === productInfo.titre) {
                                  return (
                                    <option key={index} value={produit.titre}>
                                      {produit.titre}
                                    </option>
                                  );
                                }
                                return null; // Explicitly return null if the condition is not met
                              })}

                           </select>
                        </div>
                        <div className="mb-4">
                             <label className="block text-gray-700 font-bold mb-2">Prix:</label>
                             <input
                               type="number"
                               name="prix"
                               value={productInfo.prix}
                               onChange={handleChange}
                               className="w-full px-3 py-2 border border-gray-300 rounded-md"
                             />
                        </div>
                        <div className="mb-4">
                             <label className="block text-gray-700 font-bold mb-2">Old Prix:</label>
                             <input
                               type="number"
                               name="old prix"
                               value={productInfo.solde.oldprix}
                               onChange={handleChange}
                               className="w-full px-3 py-2 border border-gray-300 rounded-md"
                             />
                        </div>
                        {setLoading ? (
                          <button className="w-full mt-4  justify-center items-center flex text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>
                        ) : (
                          <button type="submit" className="w-full mt-4 text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200">Modifier</button>
                        )}
                    </form>
                 </div>
             </div>
           )}
           {adddiscount && (
             <div className="absolute top-0 z-30 left-0 w-screen h-full bg-white p-2">
                 <div className="relative flex gap-4 items-center justify-center w-full h-full flex-col">
                   <div onClick={() => !setLoading && setadddiscount(false)} className="cursor-pointer absolute top-0 right-0 p-2 text-[30px] hover:bg-gray-200 rounded-full">
                        <IoClose />
                   </div>
                   <form onSubmit={handleAddDiscount} className="max-w-lg mx-auto min-w-[250px] xs:min-w-[350px] p-6 bg-white mt-[50px] rounded-md">
                        <div className="mb-4">
                           <label className="block text-gray-700 font-bold mb-2">Titre:</label>
                           <select
                            name="titre"
                            value={productInfo.titre}
                            onChange={handleAddChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                           >
                             <option value="" disabled>Choisir Le Titre</option>
                             {Produits.map((produit,index) => (  
                                  <option key={index} value={produit.titre}>{produit.titre}</option>
                              ))}
                           </select>
                        </div>
                        <div className="mb-4">
                             <label className="block text-gray-700 font-bold mb-2">Prix:</label>
                             <input
                               type="number"
                               name="prix"
                               value={productInfo.prix}
                               onChange={handleAddChange}
                               className="w-full px-3 py-2 border border-gray-300 rounded-md"
                             />
                        </div>
                        <div className="mb-4">
                             <label className="block text-gray-700 font-bold mb-2">Old Prix:</label>
                             <input
                               type="number"
                               name="old prix"
                               value={productInfo.solde.oldprix}
                               onChange={handleAddChange}
                               className="w-full px-3 py-2 border border-gray-300 rounded-md"
                             />
                        </div>
                        {setLoading ? (
                          <button className="justify-center w-full mt-4 items-center flex text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>
                        ) : (
                          <button type="submit" className="w-full mt-4 text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition duration-200">Ajouter</button>
                        )}
                    </form>
                 </div>
             </div>
           )}
           {sureModal && (
             <div className="absolute w-full h-full top-0 left-0 z-30 bg-black bg-opacity-40 flex items-center justify-center">
                <div className="bg-white rounded-xl p-4 max-w-[400px]">
                    <div className="flex flex-col gap-4">
                        <h1 className="text-[20px] text-center">Vous étes sure de supprimer le solde?</h1>
                        <div className="w-full gap-2 items-center justify-center flex">
                            <button onClick={() => !setLoading && setsureModal(false)} className="text-[15px] uppercase px-8 py-1 bg-white text-black border border-black rounded-xl">Annuler</button>
                            {setLoading ? (<button className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl"><svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/> </svg></button>) : (<button onClick={handleDeleteDiscount} className="uppercase px-8 py-1 text-[15px] bg-blue-500 text-white hover:bg-blue-700 rounded-xl">Supprimer</button>)}
                        </div>
                    </div>
                </div>
             </div>
           )}
           <div className="w-full flex items-center px-4 justify-start">
               <h1 className="Linky md:text-[50px] text-[30px]">Soldes</h1>
           </div>
           <div className="w-full xs:px-[20px] pt-[40px] h-[700px] overflow-auto scrollbar-custom">
                <div className="flex w-full flex-col">
                    <div className="w-full flex items-center justify-end mb-4">
                        <div onClick={setAddDiscountTrue} className="flex p-2 cursor-pointer text-white text-[13px] xmd:text-[15px] rounded-full bg-blue-400 hover:bg-blue-600">
                            <FaPlus />
                        </div>
                    </div>
                    {Produits.length !== 0 ? (
                       <div className="w-[80%] xmd:hidden gap-4 flex flex-col">
                          {Produits.map((produit,index) => {
                             if(produit.ensolde) {
                               return (
                                <div key={index} className="w-[100%] bg-gray-100  relative border-l-[5px] border-blue-500 p-4 min-h-[200px]">
                                    <div onClick={() => setModal(produit)} className="absolute top-0 right-0 p-2">
                                         <FaRegTrashAlt className="text-[14px]"/>
                                    </div>
                                    <div className="flex flex-col gap-2 pt-4">
                                        <p onClick={() => setEditDiscountTrue(produit)} className="truncate text-[20px]">Nom: {produit.titre}</p>
                                        <p className="truncate text-[17px]">Prix: {produit.prix.toFixed(3)}TND</p>
                                        <p className="truncate text-[17px]">OldPrix: {produit.solde.oldprix.toFixed(3)}TND</p>
                                        <p className="truncate text-[17px]">Solde: {calcPour(produit.solde.oldprix, produit.prix)}%</p>
                                    </div>
                                </div>
                               )
                             } 
                             return null
                          })}
                      </div>
                    ) : (
                      <div className="text-center">No Produits</div>
                    )}
                    <table className="xmd:table hidden w-full border-collapse text-left">
                        <thead className="bg-neutral-800 ">
                            <tr>
                                <th className="border-b p-4 text-white">Product</th>
                                <th className="border-b p-4 text-white">Old Prix</th>
                                <th className="border-b p-4 text-white">Price</th>
                                <th className="border-b p-4 text-white">Discount</th>
                                <th className="border-b p-4 text-white"></th>
                            </tr>
                        </thead>
                        {Produits.length !== 0 ? (
                           <tbody>
                             {Produits.map((produit, index) => {
                               if (produit.ensolde) {
                                 return (
                                   <tr key={index}>
                                     <td onClick={() => setEditDiscountTrue(produit)} className="cursor-pointer border-b p-4 flex gap-2 max-w-[300px]">
                                       <p className="truncate">{produit.titre}</p>
                                     </td>
                                     <td className="border-b p-4">{produit.solde.oldprix.toFixed(3)} TND</td>
                                     <td className="border-b p-4">{produit.prix.toFixed(3)} TND</td>
                                     <td className="border-b p-4">{calcPour(produit.solde.oldprix, produit.prix)}%</td>
                                     <td className="border-b p-4 cursor-pointer" onClick={() => setModal(produit)}><FaRegTrashAlt className="text-[14px]" /></td>
                                   </tr>
                                 );
                               }
                               return null; // Explicitly return null if the condition is not met
                             })}
                           </tbody>
                         ) : (
                           <div className="text-center">No Produits</div>
                         )}
                    </table>
                </div>
            </div>
        </div>
     </section>
  )
}

export default Soldes